import cx from 'clsx';
import React, { FC, ReactNode, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FileInputPreview, InputGroupInline, InputType, isEmpty, REQUIRED_FIELD, UseFormProps } from 'summer';
import AlertCertificado from './AlertCertificado';

export const LIMIT_SIZE_BYTES = 204800;
// eslint-disable-next-line no-control-regex
const REGEX_ONLY_ASCII = /^[\x00-\x7F]*$/;

export const validarSenhaCertificado = (senhaCertificadoDigital, erros) => {
  if (senhaCertificadoDigital && !REGEX_ONLY_ASCII.test(senhaCertificadoDigital)) {
    erros.push('A senha informada possui caracteres não suportados pelo Calima.');
  }
};

interface CertificadoFormProps {
  certificadoAtual: any;
  setCertificadoAtual: (certificadoAtual: any) => void;
  formProps: UseFormProps;
  extraContent?: ReactNode;
  customTopContent?: ReactNode;
}

const CertificadoForm: FC<CertificadoFormProps> = ({ certificadoAtual, setCertificadoAtual, formProps, extraContent, customTopContent }) => {
  const arquivos = formProps.watch('arquivos');
  const required = !!(certificadoAtual || (arquivos && arquivos.length > 0));

  useEffect(
    () => {
      if (arquivos && arquivos.length === 0 && !certificadoAtual) {
        formProps.triggerValidation('senhaCertificadoDigital');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [arquivos, certificadoAtual]
  );

  const removerCertificado = () => {
    setCertificadoAtual(undefined);
    formProps.setValue([{ arquivoCertificadoDigital: null }, { nomeCertificadoDigital: null }, { senhaCertificadoDigital: null }]);
  };

  const validate = value => isEmpty(value) && required ? REQUIRED_FIELD : null;

  return (
    <>
      <AlertCertificado />
      {customTopContent}
      <Row className={cx({ 'd-none': !certificadoAtual })}>
        <InputGroupInline id="arquivoCertificadoDigital" formProps={formProps} hidden={true} />
        <Col>
          <InputGroupInline id="nomeCertificadoDigital" formProps={formProps} label="Certificado digital" disabled={true} />
        </Col>
        <Col className="col-auto" style={{ marginTop: 20 }}>
          <Button size="sm" className="btn-danger" onClick={removerCertificado}>
            Remover certificado
          </Button>
        </Col>
      </Row>
      <Row className={cx({ 'd-none': certificadoAtual })}>
        <Col>
          <FileInputPreview
            acceptedTypes=".pfx"
            formProps={formProps}
            isMultiple={false}
            limitSizeBytes={LIMIT_SIZE_BYTES}
            propImageName="arquivos"
          />
        </Col>
      </Row>
      <Row className={cx({ 'mt-2': !certificadoAtual })}>
        <Col>
          <InputGroupInline
            formProps={{ ...formProps, validation: { validate, maxLength: 150 } }}
            id="senhaCertificadoDigital"
            label="Senha certificado digital"
            type={InputType.PASSWORD}
          />
        </Col>
        {extraContent}
      </Row>
    </>
  );
};

export default CertificadoForm;
